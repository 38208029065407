import React from 'react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import EggsGridImage from '../../../assets/images/eggs-grid.png'


const propTypes = {
    ...SectionProps.types
  }
  
  const defaultProps = {
    ...SectionProps.defaults
  }
  
  const EggsGrid = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
  
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );
  
    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
  
    return (
      <section
        {...props}
        className={outerClasses}
        style={{background: `url(${EggsGridImage}) no-repeat`, backgroundSize: "cover", height: "420px"}}
      >
        <div className="container">
            <div className={innerClasses}>
                <Box className="" >
                    
                </Box>
            </div>
        </div>
      </section>
    );
  }
  
  EggsGrid.propTypes = propTypes;
  EggsGrid.defaultProps = defaultProps;
  
  export default EggsGrid;