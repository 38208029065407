import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Grid, Box } from '@mui/material'
import Logo from '../../assets/images/kiwii-labs-logo.png';
import FooterSocial from './partials/FooterSocial';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
            
          <div className="footer-top">
            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5} md={5}>
                  <img src={ Logo } width="200" alt="" className="float-righ"/>
                </Grid>
                <Grid item xs={12} sm={7} md={7}>
                  <Grid container spacing={4}>
                    {/* <Grid item md={4} sm={4} xs={12}>
                      <h5 style={{color: "white"}}>Product</h5>
                      <p>Competitions</p>
                      <p>Datasets</p>
                      <p>Notebooks</p>
                      <p>Learn</p>
                    </Grid> */}
                    <Grid item md={6} sm={6} xs={12}>
                      <h5 style={{color: "white"}}>Product</h5>
                      <p><Link to="/about">About Kiwii Labs</Link></p>
                      <p><a href="https://docs.google.com/forms/d/e/1FAIpQLSc4lBF9PPAM3WFbagn1wqkafYsOh6MfER1oJi-eY8fzDZeofQ/viewform" target="_blank" rel="noopener noreferrer">Partner with us</a></p>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <h5 style={{color: "white"}}>Company</h5>
                      <p><a href="https://skunkdao.io/" target="_blank" rel="noreferrer noopener">Our Team</a></p>
                      <p><a href=" https://medium.com/skunkdao" target="_blank" rel="noreferrer noopener">Blog</a></p>
                      <p><a href="https://discord.gg/mc7Qd39rew" target="_blank" rel="noreferrer noopener">Contact Us</a></p>
                      <FooterSocial />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={12} xs={12}>
                  <div className="footer-copyright" style={{color: "#9CA9B3", textAlign: 'center'}}>&copy; 2022 Kiwii Labs. All right reserved</div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;