
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import { Grid, Box } from '@mui/material';
import KiwiiExperimentBg from '../../assets/images/kiwii-experiment.png'
import KiwiiCard from '../../assets/images/kiwii-card.png'
import BearCard from '../../assets/images/bear-card.png'
import TrollCard from '../../assets/images/trolls-card.png'
// import Button from '../elements/Button'
import { Swiper, SwiperSlide } from "swiper/react/swiper-react"

// import required modules
import { Navigation } from "swiper";

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const folders = [
    { image: KiwiiCard, to: "/kiwii-eggs", text: "Kiwii Eggs is the first NFT project to come out of KiwiiLabs. More details about utility are on the way but one thing we know for sure - there is a ton to look forward to" },
    { image: BearCard, to: "/", text: "Watch this space" },
    { image: TrollCard, to: "/", text: "Watch this space" },
]

const LabsExperiment = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
      <section
      id="projects"
      {...props}
      className={outerClasses}
      style={{background: `url(${KiwiiExperimentBg}) no-repeat`, backgroundSize: "cover"}}
    >
      <div className="container">
        <div className={innerClasses} sx={{textAlign: "center"}}>
            <Box sx={{mb: 2, textAlign: "center"}} className="center-img">
                {/* <Image src={TrollLogo} /> */}
                <h3 style={{color: "#fff"}}>Kiwii Labs Experiments</h3>
                {/* <Button className="button button-primary button-sm">Register</Button> */}
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center"}} className="swipe-pc">
              <Grid container spacing={3}>
                  {folders.map((folder, index) => (
                      <Grid item key={index} xs={12} md={4} sm={4}>
                        <Box sx={{mb: 20}}>
                          <Link to={folder.to}>
                            <Image src={folder.image} />
                            {folder.image !== KiwiiCard ? 
                              ( <span>
                                <p className="eggs-card">{folder.text}</p>
                                <h5 style={{color: "black", marginTop: "-130px"}}>Coming soon</h5>
                              </span>
                              ) : (
                              <p variant="p" className="eggs-card">{folder.text}</p>
                              )
                            }
                            
                          </Link>
                        </Box>
                      </Grid>
                  ))}
              </Grid>

             
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center"}} className="swipe-mobile">
              <Grid container spacing={3}>
              <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                  {folders.map((folder, index) => (
                      <Grid item key={index} xs={12} md={4} sm={4}>
                        <Box sx={{mb: 20}}>
                        <SwiperSlide
                        >
                          <Link to={folder.to}>
                            <Image src={folder.image} />
                            {folder.image !== KiwiiCard ? 
                              ( <span>
                                <p className="eggs-card">{folder.text}</p>
                                <h5 style={{color: "black", marginTop: "-130px"}}>Coming soon</h5>
                              </span>
                              ) : (
                              <p className="eggs-card">{folder.text}</p>
                              )
                            }
                            
                          </Link>
                          </SwiperSlide>
                        </Box>
                      </Grid>
                  ))}
              </Swiper>
              </Grid>
            </Box>
            {/* <Link to="/">
                <p style={{ fontWeight: 'bold', textAlign: "center", fontSize: '16px', color: '#fff', cursor: 'pointer' }}>
                View all<span style={{ position: 'absolute' }}><ArrowForwardIosIcon /></span>
                </p>
            </Link> */}
        </div>
      </div>
    </section>
    
  );
}

LabsExperiment.propTypes = propTypes;
LabsExperiment.defaultProps = defaultProps;

export default LabsExperiment;