import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import Button from './elements/Button'
import Image from './elements/Image'
import useMetaMask from '../hooks/useMetaMask'
import {  Stack, Box } from '@mui/material'


const ConnectButton = () => {
    const [, setWallet] = useState('')
    const [, setStatus] = useState('')

    const { connect, disconnect, isActive, account } = useMetaMask()
    useEffect(() => {      
        async function fetchWallet() {
          addWalletListener();
        }
        fetchWallet();
      }, []);

      function addWalletListener() {
        if (window.ethereum) {
          window.ethereum.on("accountsChanged", (accounts) => {
            if (accounts.length > 0) {
              setWallet(accounts[0]);
              setStatus("👆🏽 Write a message in the text-field above.");
            } else {
              console.log("Please connect to Nahmii network")
            }
          });
        } else {
          setStatus(
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" rel="noopener noreferrer" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          );
        }
      }

    const disconnectWallet = () => {
      Swal.fire({
        title: 'Warning!',
        text: 'Disconnect?',
        confirmButtonText: "Yes, I'm sure!"
      }).then((result) => {
        console.log(result)
        if (result.isConfirmed) {
          disconnect()
          Swal.fire(
            'Success!',
            'Disconnected successfully!',
            'success'
          )
        }
      })
    }

    return <>
    {
      account ? 
      <span className='acc-mobile' onClick={disconnectWallet}>
        <Stack direction='row'>
          <Image className='wallet-image' src={`https://avatars.dicebear.com/api/jdenticon/${account}.svg?r=50`} style={{ marginTop: '0px', marginLeft: "10px", cursor: 'pointer', paddingRight: '7px', width: '45px' }}  />
          <Box sx={{mt: 3}}>
              <p style={{ cursor: 'pointer', color: "white", fontSize: "14px", lineHeight: "16px", fontWeight: "bold" }}>
                { isActive ?   
                  String(account).substring(0,15) + "..." : ""}
                  <span className="wallet-text" style={{fontSize: "12px", color: 'rgba(255, 255, 255, 0.6)'}}><br/>Wallet</span>
              </p>
              
          </Box>
        </Stack>
      </span>
      :  <Button className="conn-btn button button-primary button-sm" onClick={connect}>Connect wallet</Button>
    }

      
    </>
}

export default ConnectButton