import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { Card, CardContent, Box, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material'
import Button from '../../elements/Button';
import { useAdmin } from '../../../hooks/useAdmin';

const RevokeRole = () => {
    const [role, setRole] = useState("")
    const [admin, setAdmin] = useState("")
    const [superAdmin, setSuperAdmin] = useState("")
    const [address, setAddress] = useState("")

    const [disableBtn, setDisableBtn] = useState(false)
    const [btnText, setBtnText] = useState("Revoke")

    const { revoke, superAdminRole, adminFunctionRole } = useAdmin()

    useEffect(() => {   
        adminRole()
        getSuperAdminRole()
    }, [])

    const adminRole = async () => {
        const result = await adminFunctionRole()
        setAdmin(result)
        return result
    }
    
    const getSuperAdminRole = async () => {
        const result = await superAdminRole()
        setSuperAdmin(result)
        return result
    }

    const handleChange = (e) => {
        setRole(e.target.value)
    }

    const handleRequest = async (e) => {
        e.preventDefault()
        try {
            if (address === "") {
                Swal.fire({
                  title: 'Warning!',
                  text: 'Kindly fill in the fields!',
                  icon: 'warning',
                  confirmButtonText: 'Close'
                })
                return
            } 	


            setDisableBtn(true)
            setBtnText("Revoking...")
            const result = await revoke(role, address)
            const status = result.status
            if(status) {
                setDisableBtn(false)
                setBtnText('Revoke')
                setAddress("")
                setRole("")
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Role granted successfully!',
                    footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.testnet.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                    confirmButtonText: 'Close',
                })
            } else {
                setDisableBtn(false)
                setBtnText('Revoke')
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something unexpected happened!',
                    confirmButtonText: 'Close',
                })
            }
        } catch (error) {
            console.error(error)
        }
        
    }

    return (
        <Card sx={{ p: 3}}>
            <CardContent>
                <Typography id='modal-modal-title' variant='p'>Revoke Role</Typography>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {mb: 2, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <div>
                        <FormControl fullWidth sx={{mb: 2}}>
                            <InputLabel id="demo-simple-select-label">Role</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={role}
                                label="Role"
                                onChange={handleChange}
                            >
                                <MenuItem value={superAdmin}>Super Admin</MenuItem>
                                <MenuItem value={admin}>Admin</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                        required
                        id="outlined-required"
                        label="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <Button variant="contained" disabled={disableBtn} onClick={handleRequest}>{btnText}</Button>
                </Box>
            </CardContent>
        </Card>
    )
}

export default RevokeRole