import React from 'react';
import { Box } from '@mui/material';

import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import AboutHeader from '../../../assets/images/about-header.jpg'
// import Button from '../elements/Button'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${AboutHeader}) no-repeat`, backgroundSize: "cover", height: "800px"}}
    >
      <div className="container">
          <div className={innerClasses} sx={{textAlign: "center"}}>
              <Box sx={{mb: 2, textAlign: "center"}} className="center-img">
                  <h2 style={{color: "#fff"}}>What is Kiwii Labs?</h2>
                  <p style={{color: "#fff"}} >Kiwii Labs is an NFT minting station powered by Nahmii’s incredibly low transaction fees. NFTs minted on Kiwii Labs are permanently etched on the blockchain and with Nahmii’s instant finality, transactions are processed faster than ever before </p>
              </Box>
              <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                  
              </Box>
          </div>
        </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;