import React from 'react';
import { Link } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import Err404 from '../assets/images/404.png'
import Image from '../components/elements/Image';

const NotFound = () => {
    return (
        <div className='container-sm'>
            <Box sx={{mt: 6}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className="centerelem" order={{ md: 1, xs: 2 }} md={6} sm={6}>
                        <h1 style={{marginTop: '50px'}} className='h1-big'>404</h1>
                        <h3>Ooooooooops!</h3>
                        <p>We couldn’t find the page you’re looking for :(</p>
                        <Link to="/" className="button button-primary button-md">Back to home</Link>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ md: 2, xs: 1 }} sm={6}>
                        <Image src={Err404} />
                    </Grid>
                </Grid> 
            </Box>
            
        </div>
    )
}

export default NotFound