import React from 'react';
import { Grid, Box } from '@mui/material';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import HumbleNinja from '../../../assets/images/egg1.png'
import ScottishCowboy from '../../../assets/images/egg2.png'
import RoyalPineapple from '../../../assets/images/egg4.png'

const contents = [
    {image: HumbleNinja, heading: "Humble Ninja", paragraph: "The Humble Ninja Kiwii always seems to get the most mushrooms by the end of the night but never brags about it "},
    {image: ScottishCowboy, heading: "Scottish Cowboy", paragraph: "The Scottish Cowboy Kiwii’s voice is gentle and soothing, and is always kind to everyone he meets"},
    {image: RoyalPineapple, heading: "Royal Pineapple", paragraph: "The Royal Pineapple loves spending time with his chefs to create the best mushroom preparations"}
]
const propTypes = {
    ...SectionProps.types
  }
  
  const defaultProps = {
    ...SectionProps.defaults
  }
  
  const WhatAreKiwiiEggs = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
  
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );
  
    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
  
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
            <div className={innerClasses}>
                <Box className="" style={{textAlign: "center"}} >
                    <h4>What are the Kiwii Eggs</h4><br/>
                    
                    <Grid container spacing={3}>
                      { contents.map((content, index) => (
                        <Grid key={index} item xs={12} md={4} sm={4}>
                            <Image src={content.image} style={{width: "120px", borderRadius: "100px"}} />
                            <h6 sx={{mb: -3, position: "relative"}}>{content.heading}</h6>
                              <p sx={{mt: -5}}>{content.paragraph}</p>
                        </Grid>
                      )) }
                    </Grid>
                           
                </Box>
            </div>
        </div>
      </section>
    );
  }
  
  WhatAreKiwiiEggs.propTypes = propTypes;
  WhatAreKiwiiEggs.defaultProps = defaultProps;
  
  export default WhatAreKiwiiEggs;