import React from 'react';
import { Box, Card, CardContent } from '@mui/material';
import classNames from 'classnames';
import Image from '../../elements/Image';
// import CountdownTimer from './partials/CountdownTimer';
import { SectionProps } from '../../../utils/SectionProps';
import LaunchingSoonImage from '../../../assets/images/about/kiwii-eggs-island.jpg'
import SoldOut from '../../../assets/images/sold-out-label.png'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const LaunchingSoon = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  // eslint-disable-next-line
  const FIVE_DAYS_IN_MS = ((5 * 24 * 60 * 60 * 1000) - 14400000);
  // eslint-disable-next-line
  const NOW_IN_MS = new Date().getTime();

  // const dateTimeAfterThreeDays = NOW_IN_MS + FIVE_DAYS_IN_MS;


  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${LaunchingSoonImage}) no-repeat`, backgroundSize: "cover"}}
    >
      <div className="container">
        <div className={innerClasses}>
            <Box className="" sx={{textAlign: "center"}}>
                {/* <h4>LAUNCHING SOON</h4> */}
                <Card sx={{mb: 5, mt: 5}} style={{backgroundColor: "rgba(0, 125, 150, 0.8)"}}>
                    <CardContent>
                        <Box className="pad-5">
                            <h1 style={{color: "white"}}>555 Kiwii Eggs</h1>
                            {/* <h5 style={{color: "white", marginTop: "30px"}}>Public mint starts in</h5>
                            <CountdownTimer style={{marginTop: "-70px"}} targetDate={dateTimeAfterThreeDays} /> */}
                            <p style={{color: "#fff"}} >Our NFTs are stored on the Nahmii blockchain and the project’s rules are controlled via smart contracts: these smart contracts ensure that the newly minted Kiwii Eggs are truly unique, permanently recorded on the blockchain, 100% owned by you</p>
                            <Image src={SoldOut} style={{width: "200px"}} />
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                
            </Box>
        </div>
      </div>
    </section>
  );
}

LaunchingSoon.propTypes = propTypes;
LaunchingSoon.defaultProps = defaultProps;

export default LaunchingSoon;