import React from 'react';
import { Grid } from '@mui/material';

import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import HeroBackground from '../../assets/images/home-hero-bg.jpg'
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${HeroBackground}) no-repeat`, backgroundSize: "cover", height: "800px"}}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6} style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "2em"}} className="btns">
              <h1 className="mt-0 mb-16 reveal-from-bottom" style={{color: "#fff"}} data-reveal-delay="200">
              Enter Kiwii Labs
              </h1>
              <p className="m-0 mb-32 reveal-from-bottom" style={{color: "#fff"}} data-reveal-delay="400">
              Welcome to the future of NFTs: Kiwii Labs, powered by the Nahmii network. Minting new NFTs has never been easier, cheaper or faster. Have an amazing idea? We would love to hear from you!
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4lBF9PPAM3WFbagn1wqkafYsOh6MfER1oJi-eY8fzDZeofQ/viewform" target="_blank" rel="noopener noreferrer" className="button button-primary button-sm">Register your interest</a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>

              <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
                <Image
                  className='fook'
                  src={require('./../../assets/images/lab-3d-fooklab.png')}
                  alt="3D Fooklab" 
                  />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
