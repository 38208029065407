import React from 'react';
import { Box } from '@mui/material';
import { HashLink } from 'react-router-hash-link';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import KiwiiEggsImage from '../../../assets/images/kiwii-eggs-image.png'
import KiwiiEggsWhite from '../../../assets/images/kiwii-eggs-white.png'
import SoldOut from '../../../assets/images/sold-out-label.png'
import KiwiiEggsHeader from '../../../assets/images/about/kiwii-eggs-header.jpg'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${KiwiiEggsHeader}) no-repeat`, backgroundSize: "cover", height: "800px"}}
    >
      <div className="overlay">
        <div className="container">
          <div className={innerClasses} sx={{textAlign: "center"}}>
              <Box sx={{mb: 2, textAlign: "center"}} className="center-img">
                  <Image src={KiwiiEggsImage} className="egg-black" />
                  <Image src={KiwiiEggsWhite} className="egg-white" />
                  <p className="p-kiwii" >Welcome to the land of Nahmiia, home of Kiwii Eggs. This spring Kiwii Birds came to Nahmiia and laid 555 NFT Eggs. No one knows what’s inside the egg. These little creatures are very secretive. But think along the lines of exclusive access to the growing Nahmii ecosystem, extra perks and magic hatching rituals </p>
                  {/* <HashLink to="#mint" className="button button-primary button-sm">Mint</HashLink> */}
                  <Image src={SoldOut} style={{width: "200px"}} />
              </Box>
              <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                  
              </Box>
          </div>
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;