import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material'
import Button from '../../elements/Button';
import Swal from 'sweetalert2'
import { useAdmin } from '../../../hooks/useAdmin';
// import useMetaMask from '../../../hooks/useMetaMask';

const Pause = () => {
    
    const [value, setValue] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false)
    const [trueBtnText, setTrueBtnText] = useState("Set to True")
    const [falseBtnText, setFalseBtnText] = useState("Set to False")

    // const { account } = useMetaMask()
    const { getPaused, setPauseFalse, setPauseTrue } = useAdmin()
    
    useEffect(() => {
        isPaused()
    },[value])

    // console.log("Account address: ", account)

    const isPaused = async () => {
        const result = await getPaused()
        setValue(result)
        return result
    }  

    const handleTrue = async () => {
        try {
            setDisableBtn(true)
            setTrueBtnText("Setting to true...")

            const result = await setPauseTrue()
            const status = result.status

            if (status) {
                setDisableBtn(false)
                setTrueBtnText('Set to True')
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Pause status set to True successfully!',
                    footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.testnet.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                    confirmButtonText: 'Close',
                }).then((result) => {
                    if (result.isConfirmed) {
                      isPaused()
                    }
                })
            } 
            else {
                setDisableBtn(false)
                setTrueBtnText('Set to True')
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something unexpected happened!',
                    confirmButtonText: 'Close',
                })
            }

        } catch (error) {
            console.error(error)
        }
    }

    const handleFalse = async () => {
        try {
            setDisableBtn(true)
            setFalseBtnText("Setting to false...")

            const result = await setPauseFalse()
            const status = result.status

            if (status) {
                setDisableBtn(false)
                setFalseBtnText('Set to False')
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Pause status set to False successfully!',
                    footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.testnet.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                    confirmButtonText: 'Close',
                }).then((result) => {
                    if (result.isConfirmed) {
                      isPaused()
                    }
                })
            } 
            else {
                setDisableBtn(false)
                setFalseBtnText('Set to False')
                Swal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Something unexpected happened!',
                    confirmButtonText: 'Close',
                })
            }

        } catch (error) {
            console.error(error)
        }
    }

   

    return (
        <Card sx={{p: 3}}>
            <CardContent>
                <Typography id='modal-modal-title' variant='p'>Pause Period ({String(value)})</Typography><br/>
                
                { value ?
                    <Button variant="contained" disabled={disableBtn} onClick={handleFalse}>{falseBtnText}</Button>
                : 
                <Button variant="contained" disabled={disableBtn} onClick={handleTrue}>{trueBtnText}</Button>
                }
            </CardContent>
        </Card>
    )
}

export default Pause