import React from 'react';
// import sections
import Hero from '../components/sections/Hero';

import LayoutDefault from '../layouts/LayoutDefault'
import KiwiiEggs from '../components/sections/KiwiiEggs';
import FookBears from '../components/sections/FookBears';
import SleepingTrolls from '../components/sections/SleepingTrolls';
import LabsExperiment from '../components/sections/LabsExperiment';

const Home = () => {

  return (
    <LayoutDefault>
      <Hero className="illustration-section-01" />
      <KiwiiEggs/>
      <FookBears/>
      <SleepingTrolls/>
      <LabsExperiment/>
    </LayoutDefault>
  );
}

export default Home;