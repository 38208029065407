import React, { useState } from 'react';
import Swal from 'sweetalert2'
import { Card, CardContent, Box, TextField, Typography } from '@mui/material'
import Button from '../../elements/Button';
import { useAdmin } from '../../../hooks/useAdmin';

const AddWhitelistUserAddress = () => {
    const [addresses, setAddresses] = useState("")
    const [id, setId] = useState("")

    const [disableBtn, setDisableBtn] = useState(false)
    const [btnText, setBtnText] = useState("Whitelist")

    const { whitelistAddress } = useAdmin()

    const handleRequest = async (e) => {
        e.preventDefault()
        try {
            if (addresses === "" && id === "") {
                Swal.fire({
                  title: 'Warning!',
                  text: 'Kindly fill in the fields!',
                  icon: 'warning',
                  confirmButtonText: 'Close'
                })
                return
            } 	

            const addressArray = addresses.split(" ")

            setDisableBtn(true)
            setBtnText("Whitelisting addresses...")
            const result = await whitelistAddress(addressArray, id)
            const status = result.status
            if(status) {
                setDisableBtn(false)
                setBtnText('Whitelist')
                setAddresses("")
                setId("")
                Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Whitelisted addresses successfully!',
                footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.testnet.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                confirmButtonText: 'Close',
                })
            } else {
                setDisableBtn(false)
                setBtnText('Whitelist')
                setAddresses("")
                setId("")
                Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Something unexpected happened!',
                confirmButtonText: 'Close',
                })
            }
        } catch (error) {
            console.error(error)
        }
        
    }

    return (
        <Card sx={{ p: 3}}>
            <CardContent>
                <Typography id='modal-modal-title' variant='p'>Whitelist User Address</Typography>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {mb: 2, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <div>
                        <TextField
                        required
                        id="outlined-required"
                        label="Address"
                        value={addresses}
                        onChange={(e) => setAddresses(e.target.value) }
                        />
                        <TextField
                        required
                        id="outlined-required"
                        label="ID"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        />
                    </div>
                    <Button variant="contained" disabled={disableBtn} onClick={handleRequest}>{btnText}</Button>
                </Box>
            </CardContent>
        </Card>
    )
}

export default AddWhitelistUserAddress