import React from 'react';
import useMetaMask from './hooks/useMetaMask';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppRoute from './routes';

const THEME = createTheme({
  typography: {
   "fontFamily": `'Poppins', sans-serif`,
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  },
  textField: {
    border: "none"
  },
  palette: {
    primary: {
      main: '#000000'
    }
  }
});

const App = () => {
  const { isLoading } = useMetaMask()

  return (
    <>
      { isLoading ? '' : 
      <React.Fragment>
        <ThemeProvider theme={THEME}>
          <AppRoute />
        </ThemeProvider>
      </React.Fragment>
      }
    </>
  );
}

export default App;
