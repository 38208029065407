import React from 'react'
import { Box } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';

const Banner = () => {
    const [show, setShow] = React.useState(true)

    const dismissBanner = () => {
        setShow(false)
    }
    return (
        <>
        { 
            show ? <Box className='center' style={{backgroundColor: "#B52876", padding: "10px 10px 1px 10px"}}>
                <p style={{color: "white", textAlign: "right", marginTop: "5px", marginRight: "50px", fontSize: "14px"}}>Visit the <span><a target="_blank" href="https://bridge.nahmii.io" rel="noopener noreferrer" style={{fontWeight: "bold", color: "white", textDecoration: "underline"}}>Nahmii Bridge</a></span> to deposit funds into Nahmii and use Kiwii Labs <ClearIcon style={{position: "absolute", marginTop: "1px"}} onClick={dismissBanner} /></p>  
            </Box> : ""
        }
            
        </>
        
    )
}

export default Banner