import React from 'react'
import CreateWithKiwiiLabs from '../components/sections/about/CreateWithKiwiiLabs'
import Hero from '../components/sections/about/Hero'
import RoadMap from '../components/sections/about/RoadMap'
import SkunkDaoIncubator from '../components/sections/about/SkunkDaoIncubator'
import LayoutDefault from '../layouts/LayoutDefault'

const AboutPage = () => {
    return (
        <LayoutDefault>
            <Hero/>
            <RoadMap/>
            <SkunkDaoIncubator/>
            <CreateWithKiwiiLabs/>
        </LayoutDefault>
    )
}

export default AboutPage