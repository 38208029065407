
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import { Grid, Box } from '@mui/material';
import KiwiiEggsBg from '../../assets/images/kiwii-eggs-bg2.png'
import KiwiiEggsImage from '../../assets/images/kiwii-eggs-image.png'
import Egg1 from '../../assets/images/sold-out-kiwii-eggs-01.jpg'
import Egg2 from '../../assets/images/sold-out-kiwii-eggs-02.jpg'
import Egg3 from '../../assets/images/sold-out-kiwii-eggs-03.jpg'
import Button from '../elements/Button'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const eggs = [
    { image: Egg1, to: "/kiwii-eggs", },
    { image: Egg2, to: "/kiwii-eggs", },
    { image: Egg3, to: "/kiwii-eggs", },
]

const KiwiiEggs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
      <section
      {...props}
      className={outerClasses}
      style={{background: `url(${KiwiiEggsBg}) no-repeat`, backgroundSize: "cover"}}
    >
      <div className="container">
        <div className={innerClasses} sx={{textAlign: "center"}}>
            <Box sx={{mb: 2, textAlign: "center"}} className="center-img">
                <Image src={KiwiiEggsImage} />
                <p style={{color: "#000", textAlign: "center", fontWeight: "bold"}} >Grab your share of the 555 generation 0 Kiwii Eggs - unique exclusive collectible NFTs with proof of ownership stored on the Nahmii blockchain</p>
                <HashLink to="/kiwii-eggs#mint">
                  <Button style={{cursor: "pointer"}} className="button button-primary button-sm">Find out more</Button>
                </HashLink>
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center", p: 5}}>
                <Grid container spacing={3}>
                    {eggs.map((egg, index) => (
                        <Grid item key={index} xs={12} md={4} sm={4}>
                            <Link to={egg.to}>
                              <Image src={egg.image} />
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <HashLink to="/kiwii-eggs#mint">
              <p style={{ fontWeight: 'bold', textAlign: "center", fontSize: '16px', color: '#000', cursor: 'pointer' }}>
                View all<span style={{ position: 'absolute' }}><ArrowForwardIosIcon /></span>
              </p>
            </HashLink>
        </div>
      </div>
    </section>
    
  );
}

KiwiiEggs.propTypes = propTypes;
KiwiiEggs.defaultProps = defaultProps;

export default KiwiiEggs;