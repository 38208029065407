import React, { useState } from 'react';
import { Card, CardContent, Box, TextField, Typography } from '@mui/material'
import Button from '../../elements/Button';
import Swal from 'sweetalert2'
import { useAdmin } from '../../../hooks/useAdmin';

const SetCost = () => {
    const [cost, setCost] = useState("")
    const [disableBtn, setDisableBtn] = useState(false)
    const [btnText, setBtnText] = useState("Set Cost")
    const { setNewCost } = useAdmin()

    const handleRequest = async (e) => {
        e.preventDefault()

        try {
            if (cost === "") {
                Swal.fire({
                  title: 'Warning!',
                  text: 'Kindly fill in the field!',
                  icon: 'warning',
                  confirmButtonText: 'Close'
                })
                return
            } 
            setDisableBtn(true)
            setBtnText("Setting cost...")
            const result = await setNewCost(cost)
            const status = result.status
            if(status) {
                setDisableBtn(false)
                setBtnText('Set cost')
                Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Set cost successfully!',
                footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.testnet.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                confirmButtonText: 'Close',
                })
            } else {
                setDisableBtn(false)
                setBtnText('Set cost')
                Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Something unexpected happened!',
                confirmButtonText: 'Close',
                })
            }
        } catch (error) {
            console.error(error)
        }
       
    }

    return (
        <Card sx={{ p: 3}}>
            <CardContent>
                <Typography id='modal-modal-title' variant='p'>Set New Cost</Typography>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {mb: 2, width: '100%' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <div>
                        <TextField
                        required
                        id="outlined-required"
                        label="Enter new cost"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        />
                    </div>
                    <Button variant="contained" disabled={disableBtn} onClick={handleRequest}>{btnText}</Button>
                </Box>
            </CardContent>
        </Card>
    )
}

export default SetCost