import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Logo from '../../assets/images/kiwii-labs-logo.png';
import ConnectButton from '../ConnectButton';
import { Stack } from '@mui/material'

import useMetaMask from '../../hooks/useMetaMask';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);
  const [networkName, setNetworkName] = useState("")

  const nav = useRef(null);
  const hamburger = useRef(null);

  const { account } = useMetaMask()

  useEffect(() => {
    checkNetwork()
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      closeMenu();
    };
  });  

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const checkNetwork = () => {
    try {
      const networkId = window.ethereum.networkVersion
      if (networkId === '5553') {
        setNetworkName("Testnet")
      } else if (networkId === '5551') {
        setNetworkName("Mainnet")
      } else {
        setNetworkName("Unknown")
      }
    } catch (error) {
      console.error(error)
    }
    
  }

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className
  );

  return (
    <div>
     
      <header
      {...props}
      className={classes}
      style={{backgroundColor: "black"}}
    > 
      <div className="overlay">
        <div className="container">
          <div className={
            classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}>
            {/* <Logo /> */}
            <Link to="/"><img src={ Logo } width="200" alt="" className="float-righ"/></Link>
            {/* <h3>Numeri</h3> */}
            {!hideNav &&
              <>
                <button
                  ref={hamburger}
                  className="header-nav-toggle"
                  onClick={isActive ? closeMenu : openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={nav}
                  className={
                    classNames(
                      'header-nav',
                      isActive && 'is-active'
                    )}>
                  <div className="header-nav-inner">
                    <ul className={
                      classNames(
                        'list-reset text-xs',
                        navPosition && `header-nav-${navPosition}`
                      )}>
                        <li style={{color: "#fff"}}>
                          <HashLink to="/#projects">Projects</HashLink>
                        </li>
                      {/* <li>
                        <Link to="/writing" onClick={closeMenu}>Writing</Link>
                    </li> */}
                      <li className='bbw' style={{color: "#fff"}}>
                        <Link to="/about" style={{color: "#fff"}}>About</Link>
                      </li>
                      
                      
                      <Stack direction="row" className='header-box'>
                        { account ? 
                          <>
                          
                          <li className='network-out'>
                            <span className="dot" style={{marginRight: '4px'}}></span>
                            {networkName} <br className="d-non"/><span className='network'>Network</span>
                          </li> 
                          <li className='div-line' style={{borderLeft: "1px solid rgba(255, 255, 255, 0.24)", height: "40px"}}>&nbsp;</li>
                          </>
                     
                      
                        : "" }
                        <li>
                          <ConnectButton />
                        </li>
                      </Stack>

                      
                    </ul>
                    {/* <hr style={{color: "white"}}/>
                    {!hideSignin &&
                      <ul
                        className="list-reset header-nav-right"
                      >
                        <li style={{marginLeft: "20px"}}>
                          <ConnectButton />
                        </li>
                      </ul>} */}
                  </div>
                </nav>
              </>}
          </div>
        </div>
      </div>
    </header>
    </div>
    
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

// #8364e2