import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from '../utils/ScrollToTop';
import AboutPage from '../views/About';
import Admin from '../views/Admin';

import Home from '../views/Home'
import KiwiiEggs from '../views/KiwiiEggs';
import NotFound from '../views/NotFound';

const AppRoute = () => {
  return (
      <Router>
        <ScrollToTop>
          <Routes>
            <Route exact path="/" element={<Home/>} />
            <Route exact path="/kiwii-eggs" element={<KiwiiEggs/>} />
            <Route exact path="/admin" element={<Admin/>} />
            
            <Route exact path="/about" element={<AboutPage/>} />
            <Route exact path="*" element={<NotFound/>} />
            
          </Routes>
        </ScrollToTop>
      </Router>
  );
}

export default AppRoute;
