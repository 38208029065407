import * as React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent  } from '@mui/lab';
import { Grid } from '@mui/material'

const roadMapItems = [
  { time: "Jan 2022", text: "Launch of SkunkDAO Incubator" },
  { time: "Apr 2022", text: "Announcement of the first NFT collection created by Kiwii Labs - Kiwii Eggs" },
  { time: "June 2022", text: "Kiwii Labs platform goes live" },
  { time: "Summer/Fall 2022", text: "New NFT projects are announced in collaboration with Kiwii Labs" },
]

export default function RoadMap() {
  return (
    <section style={{textAlign: "center", color: "black", fontSize: "10px", paddingTop: "50px", paddingBottom: "50px", overflowX: "auto"}}>
      <React.Fragment>
        <h3 style={{textAlign: "center"}}>This is our Roadmap</h3>
        <Grid container>
          <Grid item xs={12} md={9} sm={12}>
             <Timeline style={{}}>
              { roadMapItems.slice(0,roadMapItems.length-1).map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent style={{fontSize: "0.9rem"}}>
                    {item.time}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary"/>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{fontSize: "0.9rem"}}>{item.text}</TimelineContent>
                </TimelineItem>
              )) }
              <TimelineItem key={roadMapItems.length-1}>
                  <TimelineOppositeContent style={{fontSize: "0.9rem"}}>
                    {roadMapItems[roadMapItems.length-1].time}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot variant="outlined" color="primary"/>
                  </TimelineSeparator>
                  <TimelineContent style={{fontSize: "0.9rem"}}>{roadMapItems[roadMapItems.length-1].text}</TimelineContent>
                </TimelineItem>
            </Timeline>
          </Grid>
          {/* <Grid item xs={12} md={1} sm={1}></Grid> */}
        </Grid>
       
      </React.Fragment>
    </section>
    
  );
}
