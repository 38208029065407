import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Banner from '../components/layout/Banner';

const LayoutDefault = ({ children }) => {
  return (
    <>
      <Banner/>
      <Header navPosition="right" className="reveal-from-bottom" />
      <main className="site-content" style={{overflowY: 'hidden'}}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default LayoutDefault;  