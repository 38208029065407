import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import { Grid, Box, Card, CardContent, ButtonGroup } from '@mui/material';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import MintedEggs from '../../../assets/images/kiwii-eggs-minting-sold-out.jpg'
import KiwiiEggsMint from '../../../assets/images/about/kiwii-eggs-minting-background.jpg'
import Button from '../../elements/Button'

import useMetaMask from '../../../hooks/useMetaMask';
import { useMint } from '../../../hooks/useMint';
import { useAdmin } from '../../../hooks/useAdmin';

import ConnectButton from '../../ConnectButton';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const MintEggs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const [amount, setAmount] = useState(0)
  const [walletAddress, setWallet] = useState('') 
  const [, setMessage] = useState('')

  const [disableBtn, setDisableBtn] = useState(false)
  const [mintText, setMintText] = useState("Mint Now")
  const [eggAmount, setEggAmount] = useState("")

  const [limit, setLimit] = useState(0)

  const [whitelistedAirdrip, setWhitelistedAirdrip] = useState(false)

  const { account} = useMetaMask()
  const {
    getBalance,
    getEggCost,
    mintEgg,
    mintedThird,
    mintedSecond,
    mintedAirdrip,
    getIsWhiteListed,
  } = useMint()

  const { getPaused, getOnlyWhitelisted } = useAdmin()

  useEffect(() => {
    fetchWallet()
    isWl()
    paused()
    isOnlyWhitelisted()
    isMintedAirdrip()
    isMintedStageTwo()
    isMintedStageThree()   
    egg() 
    accountBalance()
  }, [])

  // console.log("Connected Address here in MintEgg: ", account)
  const isOnlyWhitelisted = async () => {
    const result = await getOnlyWhitelisted()
    return result
  }

  const egg = async () => {
    const result = await getEggCost(amount)
    setEggAmount(result)
    return result
  }

  const accountBalance = async () => {
    const result = await getBalance(account)
    return result
  }

  const isWl = async () => {
    const onlyWl = await isOnlyWhitelisted()
    const result1 = await getIsWhiteListed(account, 1)
    const result2 = await getIsWhiteListed(account, 2)
    const result3 = await getIsWhiteListed(account, 3)
    setWhitelistedAirdrip(result1)
    if (onlyWl && result1) {
      setLimit(1)
    } else if (onlyWl && result2) {
      setLimit(2)
    } else if (onlyWl && result3) {
      setLimit(1)
    } else {
      setLimit(15)
    }    
    return { result1, result2, result3 }
  }

  const paused = async () => {
    const result = await getPaused()
    return result
  }

  const isMintedAirdrip = async () => {
    const result = await mintedAirdrip(account)
    return result
  }

  const isMintedStageTwo = async () => {
    const result = await mintedSecond(account)
    return result
  }

  const isMintedStageThree = async () => {
    const result = await mintedThird(account)
    return result
  }

  async function fetchWallet() {
    // const account = await getCurrentWalletConnected();
    setWallet(account);
    // setMessage(message); 
    addWalletListener();
  }

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setMessage("Mint eggs!");
        } else {
          setWallet("");
          setMessage("Connect to Metamask using the top right button.");
        }
      });
    } else {
      setMessage(
        <p>
          {" "}
          
          <a target="_blank" rel="noreferrer noopener" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const handleIncrement = async () => {
    if(amount < limit) {
      setAmount(amount + 1)
    }
  };

  // const handleEggLimit = () => {
  //   Swal.fire({
  //     title: "Oops!",
  //     text: "This collection is sold out. Join our discord and twitter to register for future collections."
  //   })
  // }

  const handleDecrement = () => {
    if(amount > 0) {
      setAmount(amount - 1)
    }
  };

  const onMintEggs = async () => {
    try {

      if (amount === 0) {
        Swal.fire({
          title: 'Oops!',
          text: 'Cannot mint 0 eggs!',
          confirmButtonText: 'Close'
        })
        return
      } 

      if(walletAddress > 0) {
        const eggPrice = await egg()
        const balance = await accountBalance()
        const amountToSpend = eggPrice * amount

        const isWhitelistedAll = await isWl()
        const isPaused = await paused()
        const onlyWhitelisted = await isOnlyWhitelisted()

          if (isPaused) {
            Swal.fire({
              title: 'Oops!',
              text: 'Sorry, minting is not available right now!',
              confirmButtonText: 'Close'
            })
          }
          else {
            if (onlyWhitelisted === true && isWhitelistedAll.result1 === false && isWhitelistedAll.result2 === false && isWhitelistedAll.result3 === false) {
              Swal.fire({
                title: 'Oops!',
                text: 'Sorry, your wallet address has not been whitelisted for this mint!',
                confirmButtonText: 'Close',
              })
            } 
            else {
              if (amountToSpend <= balance) {
                try {
                  setDisableBtn(true)
                  setMintText("Minting...")

                  const result = await mintEgg(amount)
                  if(result.status) {
                    setDisableBtn(false)
                    setMintText('Mint Now')
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: 'Minted ' + amount + ' eggs successfully!',
                      footer: '<a target="_blank" rel="noreferrer noopener" href="https://explorer.nahmii.io/tx/' + result.transactionHash + '">Click here to check transaction</a>',
                      confirmButtonText: 'Close',
                    })
                  } else {
                    setDisableBtn(false)
                    setMintText('Mint Now')
                    Swal.fire({
                      title: 'Oops!',
                      text: 'Something unexpected happened!',
                      confirmButtonText: 'Close',
                    })
                  }
                } catch (error) {
                  console.error(error)
                  setDisableBtn(false)
                  setMintText("Mint Now")
                }
              } else {
                Swal.fire({
                  title: 'Oops!',
                  text: 'Insufficient balance!',
                  confirmButtonText: 'Close',
                })
              }
            }
          }
        
      } else {
        setDisableBtn(false)
        setMintText("Mint Now")
        Swal.fire({
          title: 'Oops!',
          text: 'Connect wallet first!',
          confirmButtonText: 'Close'
        }) 
      }
    } catch (error) {
      console.error(error)
      }
  }

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${KiwiiEggsMint}) no-repeat`, backgroundSize: "cover"}}
      id="mint"
    >
      <div className="container">
        <div className={innerClasses}>
            <Box className="" >
                <Card sx={{mb: 5, mt: 5, p: 4}}>
                    <CardContent>
                        <Grid container spacing={5}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Image src={MintedEggs} />
                                <h3 style={{textAlign: "center"}}>Minting is now closed</h3>
                            </Grid>
                            {/* <Grid item md={7} sm={7} xs={12}>
                              <Box style={{marginTop: "30px", textAlign: "center"}}>
                                <h4>Mint your Kiwii eggs</h4>
                                <p style={{color: "#000", textAlign: "center"}} >Grab your share of the 555 generation 0 Kiwii Eggs - unique exclusive collectible NFTs with proof of ownership stored on the Nahmii blockchain</p>
                                <Box style={{marginTop: "-50px", marginBottom: "-50px"}}>
                                  <ButtonGroup size="small" aria-label="small outlined button group">
                                    <h2 style={{cursor: "pointer"}} onClick={handleDecrement}>-</h2>
                                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;{amount}&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                                    <h2 style={{cursor: "pointer"}} onClick={handleIncrement}>+</h2>
                                  </ButtonGroup>
                                </Box><br/>
                                <p>Total Amount: { whitelistedAirdrip ? <b>Free</b> : <b>{(eggAmount * amount)/10**18} ETH</b> }</p>
                                { account ? (
                                  // eggLimit === '439' ? <Button className="button button-primary button-md" style={{cursor: "pointer", width: "70%", marginTop: "0px"}} onClick={handleEggLimit}>Mint Now</Button> :
                                  <Button disabled={disableBtn} className="button button-primary button-sm" style={{width: "70%", marginTop: "0px", cursor: "pointer"}} onClick={onMintEggs}>{mintText}</Button>
                                )
                                
                                :  <ConnectButton/>
                                }
                                
                                </Box>
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
                
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                
            </Box>
        </div>
      </div>
    </section>
  );
}

MintEggs.propTypes = propTypes;
MintEggs.defaultProps = defaultProps;

export default MintEggs;