
import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import { Grid, Box } from '@mui/material';
import FookBearBg from '../../assets/images/fookbear-bg-blur.png'
// import FookBearLogo from '../../assets/images/fookbears-logo.png'
import Bear1 from '../../assets/images/bear-blur1.png'
import Bear2 from '../../assets/images/bear-blur2.png'
import Bear3 from '../../assets/images/bear-blur3.png'
// import Button from '../elements/Button'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const bears = [
    { image: Bear1 },
    { image: Bear2 },
    { image: Bear3 },
]

const FookBears = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );


  return (
      <section
      {...props}
      className={outerClasses}
      style={{background: `url(${FookBearBg}) no-repeat`, backgroundSize: "cover"}}
    >
      <div className="container">
        <div className={innerClasses} sx={{textAlign: "center"}}>
            <Box sx={{mb: 2, textAlign: "center"}} className="center-img">
                {/* <Image src={FookBearLogo} /> */}
                <h3 style={{color: "#fff"}}>Coming soon</h3>
                <p style={{color: "#fff", textAlign: "center", fontWeight: "bold"}} >Watch this space</p>
                {/* <Button className="button button-primary button-sm">Register</Button> */}
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center", p: 5}}>
                <Grid container spacing={3}>
                    {bears.map((bear, index) => (
                        <Grid item key={index} xs={12} md={4} sm={4}>
                            <Image src={bear.image} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {/* <Link to="/">
              <p style={{ fontWeight: 'bold', textAlign: "center", fontSize: '16px', color: '#fff', cursor: 'pointer' }}>
                View all<span style={{ position: 'absolute' }}><ArrowForwardIosIcon /></span>
              </p>
            </Link> */}
        </div>
      </div>
    </section>
    
  );
}

FookBears.propTypes = propTypes;
FookBears.defaultProps = defaultProps;

export default FookBears;