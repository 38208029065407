import React from 'react';
// import sections
import Hero from '../components/sections/kiwii-eggs/Hero';

import LayoutDefault from '../layouts/LayoutDefault'
import MintEggs from '../components/sections/kiwii-eggs/MintEggs';
import WhatAreKiwiiEggs from '../components/sections/kiwii-eggs/WhatAreKiwiiEggs';
import OwnerExtras from '../components/sections/kiwii-eggs/OwnerExtras';
import EggsGrid from '../components/sections/kiwii-eggs/EggsGrid';
import LaunchingSoon from '../components/sections/kiwii-eggs/LaunchingSoon';

const KiwiiEggs = () => {

  return (
    <LayoutDefault>
      <Hero className="illustration-section-01" />
      <MintEggs/>
      <WhatAreKiwiiEggs/>
      <EggsGrid/>
      <OwnerExtras/>
      <LaunchingSoon/>
    </LayoutDefault>
  );
}

export default KiwiiEggs;