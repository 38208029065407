// eslint-disable-next-line
import React from 'react'
import Web3 from 'web3'
import useMetaMask from './useMetaMask'
import { nahmiiContractAddress, kiwiiContractAddress } from '../contract'
import Swal from 'sweetalert2'

require('dotenv').config()
const nahmiiContractABI = require('../abi/Nahmii')
const newKiwiiABI = require('../abi/KiwiiEggs')

const RPC_URL = window.__RUNTIME_CONFIG__.REACT_APP_RPC_URL

const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
const web3 = new Web3(new Web3(window.ethereum || httpProvider))

const nahmiiContract = new web3.eth.Contract(nahmiiContractABI.abi, nahmiiContractAddress)
const newKiwiiContract = new web3.eth.Contract(newKiwiiABI.abi, kiwiiContractAddress)

export const useMint = () => {

  const { account } = useMetaMask()

  const getIsOnlyWhitelisted = async () => {
    try {
      const isOnlyWhitelisted = await newKiwiiContract.methods.onlyWhitelisted().call()
      return isOnlyWhitelisted
    } catch (error) {
      console.error(error)
    }
  }
    
  const getIsWhiteListed = async (address, id) => {
    try {
      const result = await newKiwiiContract.methods.isWhitelisted(address, id).call()
      return result
    } catch (error) {
      console.error(error)
    }
  }
    
  const mintedAirdrip = async (address) => {
    try {
      const result = await newKiwiiContract.methods.isMintedAirdriip(address).call()
      return result
    } catch (error) {
      console.error(error)
    }
  }
    
  const mintedSecond = async (address) => {
    try {
      const result = await newKiwiiContract.methods.isMintedStageTwo(address).call()
      return result
    } catch (error) {
      console.error(error)
    }
  }
    
  const mintedThird = async (address) => {
    try {
      const result = await newKiwiiContract.methods.isMintedStageThird(address).call()
      return result
    } catch (error) {
      console.error(error)
    }
  }
    
  const mintEgg = async (amount) => {
    
    //setup Ethereum transaction
    try {
      const eggCost = await newKiwiiContract.methods.cost().call()
      const finalAmount = eggCost * amount
      const amountInBn = await web3.utils.toBN(finalAmount)
    
      const isOnlyWhitelisted = await newKiwiiContract.methods.onlyWhitelisted().call()

      if (isOnlyWhitelisted) {
        Swal.fire({
          title: 'Oops!',
          text: 'Sorry, whitelist period is over!',
          confirmButtonText: 'Close',
        })
          
      } else {
        // eslint-disable-next-line
        const approveContract = await nahmiiContract.methods.approve(kiwiiContractAddress, amountInBn).send({from: account})
        const result = await newKiwiiContract.methods.mintForPublic(amount).send({from: account})
        return result
      }

    } catch (error) {
      console.error(error)
    }
  } 
  
  const getEggCost = async () => {
    try {
      const eggCost = await newKiwiiContract.methods.cost().call()
      return eggCost
    } catch (error) {
      console.error(error)
    }
  }
    
  const getBalance = async (address) => {
    try {
      const result = await nahmiiContract.methods.balanceOf(address).call()
      return result
    } catch (error) {
      console.error(error)
    }
  }

  const getSupply = async () => {
    try {
      const result = await newKiwiiContract.methods.totalSupply().call()
      return result
    } catch (error) {
      console.error(error)
    }
  }
    
  return {
    getSupply,
    getBalance,
    getEggCost,
    mintEgg,
    mintedThird,
    mintedSecond,
    mintedAirdrip,
    getIsWhiteListed,
    getIsOnlyWhitelisted,
  }
}