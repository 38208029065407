import React from 'react';
import { Grid, Box, Card, CardContent } from '@mui/material';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import FookLabs3D from '../../../assets/images/lab-3d-fooklab.png'
import CreatwWithkiwiiBg from '../../../assets/images/about/nfts-mosaic.jpg'


const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const CreateWithKiwiiLabs = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${CreatwWithkiwiiBg}) no-repeat`, backgroundSize: "cover"}}
    >
      <div className="container">
        <div className={innerClasses}>
            <Box className="" >
                <Card sx={{mb: 5, mt: 5}}>
                    <CardContent>
                        <Grid container spacing={5}>
                            <Grid item md={5} sm={5} xs={12}>
                                <Image src={FookLabs3D} />
                            </Grid>
                            <Grid style={{textAlign: "center"}} item md={7} sm={7} xs={12}>
                                <h3>Working on an NFT project?</h3>
                                <h4 style={{color: "#000"}} >Create with Kiwii Labs!</h4>
                                <br/>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc4lBF9PPAM3WFbagn1wqkafYsOh6MfER1oJi-eY8fzDZeofQ/viewform" target="_blank" rel="noopener noreferrer" className="button button-primary button-sm" style={{color: "#fff", backgroundColor: "#000"}}>Start now</a>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                
            </Box>
            <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                
            </Box>
        </div>
      </div>
    </section>
  );
}

CreateWithKiwiiLabs.propTypes = propTypes;
CreateWithKiwiiLabs.defaultProps = defaultProps;

export default CreateWithKiwiiLabs;