import React from 'react';
import { Box } from '@mui/material';

import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import SkunkDao from '../../../assets/images/about/about-skunkDAO.jpg'
// import Button from '../elements/Button'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const SkunkDaoIncubator = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
      style={{background: `url(${SkunkDao}) no-repeat`, backgroundSize: "cover", height: "800px"}}
    >
      {/* <div className="overlay_"> */}
        <div className="container">
          <div className={innerClasses} sx={{textAlign: "center"}}>
              <Box sx={{mb: 2, textAlign: "center"}} className="center">
                  <h2 style={{color: "#fff"}}>We are Part of SkukDAO Incubator</h2>
                  <p style={{color: "#fff", textShadow: '2px 2px #000000'}} >SkunkDAO is a dedicated community of blockchain developers building the future of Ethereum scaling on the Nahmii network. Bring your projects from idea to full production in just 12 weeks. 
                  The team behind SkunkDAO also includes experts from Nahmii AS, creators of the Nahmii protocol. Working on L2 scaling since 2017, they have years of experience building innovative web3 products across DeFi, NFTs, gaming, metaverse and more
                  </p>
                  <a href="https://skunkdao.io/" target="_blank" rel="noopener noreferrer" className="button button-primary button-sm">Learn More</a>
              </Box>
              <Box sx={{mt: 4, mb: 4, textAlign: "center"}}>
                  
              </Box>
          </div>
        </div>
      {/* </div> */}
    </section>
  );
}

SkunkDaoIncubator.propTypes = propTypes;
SkunkDaoIncubator.defaultProps = defaultProps;

export default SkunkDaoIncubator;