import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { injected } from '../utils/injected'
import { useWeb3React } from '@web3-react/core';
import Swal from 'sweetalert2'

export const MetaMaskContext = React.createContext(null)

export const MetaMaskProvider = ({ children }) => {

    // eslint-disable-next-line
    const { activate, account, library, connector, active, deactivate, error, setError } = useWeb3React()
    
    const [isActive, setIsActive] = useState(false)
    const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask
    const [isLoading, setIsLoading] = useState(true)

    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

    const { ethereum } = window;
    // Init Loading
    useEffect(() => {
      setIsLoaded(true);
      if (window.ethereum) {
        handleEthereum();
      } else {
        window.addEventListener('ethereum#initialized', handleEthereum, {
          once: true,
        });
      
        // If the event is not dispatched by the end of the timeout,
        // the user probably doesn't have MetaMask installed.
        setTimeout(handleEthereum, 3000); // 3 seconds
      }
      
      async function handleEthereum() {
        // Access the decentralized web!

        connect().then(val => {
          setIsLoading(false)
        })
      }
        
    }, [])

    // Check when App is Connected or Disconnected to MetaMask
    const handleIsActive = useCallback(() => {
        console.log('App is connected with MetaMask ', active)
        setIsActive(active)
    }, [active])

    useEffect(() => {
      handleIsActive()
      if (isLoaded) {
        setIsPageLoaded(true);
      }
    }, [handleIsActive, isLoaded])

    // eslint-disable-next-line
    const addNahmiiMainnetNetwork = async () => {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x15AF' }],
        });
        window.location.reload()
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x15AF',
                  chainName: 'Nahmii Mainnet',
                  rpcUrls: ['https://l2.nahmii.io/'] /* ... */,
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://explorer.nahmii.io/'],
                  iconUrls: ''
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
            console.error(addError)
          }
        }
        // handle other "switch" errors
      }
    }

    
    const addNahmiiTestnetNetwork = async () => {
      try {
        await ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x15B1' }],
        });
        window.location.reload()
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x15B1',
                  chainName: 'Nahmii Testnet',
                  rpcUrls: ['https://testnet.l2.nahmii.io/'] /* ... */,
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  blockExplorerUrls: ['https://testnet.explorer.nahmii.io/'],
                  iconUrls: ''
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
            console.error(addError)
          }
        }
        // handle other "switch" errors
      }
    }

    // Connect to MetaMask wallet
    const connect = async () => {
      console.log('Connecting to MetaMask...')
      setShouldDisable(true)
      try {
        if (ethereum && ethereum.isMetaMask) {
          await activate(injected, (error) => {
            if (error) {
              // console.log("error: ", UnsupportedChainIdError)
              Swal.fire({
                title: 'Oops!',
                text: 'Please connect your wallet to Nahmii network!',
                confirmButtonText: 'Click to add automatically',
                footer: '<a target="_blank" rel="noreferrer noopener" href="https://rpc.info/#nahmii-rpc">or click here to add manually</a>',
              }).then((result) => {
                if (result.isConfirmed) {
                  window.__RUNTIME_CONFIG__.REACT_APP_ENVIRONMENT === 'MAINNET' ? addNahmiiMainnetNetwork() : addNahmiiTestnetNetwork()
                }
              })
            }
          })
        } 
        else {
          if (isPageLoaded) {
            Swal.fire({
            title: 'Oops!',
            text: 'Metamask is needed to proceed',
            confirmButtonText: 'Download and Install now',
          }).then((result) => {
            if (result.isConfirmed) {
              window.open(
                'https://metamask.io/download/',
                '_blank'
              );
            }
          })
          }
          
        }
        
            // setShouldDisable(false)
      } catch(error) {
          console.log('Error on connecting: ', error)
      }
    }

    // Disconnect from Metamask wallet
    const disconnect = async () => {
        console.log('Disconnecting wallet from App...')
        try {
            await deactivate()
        } catch(error) {
            console.log('Error on disconnnect: ', error)
        }
    }

    console.log("account: ", account)
    const getCurrentWalletConnected = async () => {
      try {
        if (window.ethereum) {
          if (account) {
            return account
          } else {
            Swal.fire({
              title: 'Oops!',
              text: 'Metamask is needed to access this site',
              confirmButtonText: 'Download now',
            }).then((result) => {
              if (result.isConfirmed) {
                window.open('https://metamask.io/download/', '_blank')
              }
            })
          }
        } else {
          console.log("Install Metamask")
        }
          
      } catch (err) {
        console.error(err)
      }
    };

    const values = useMemo(() => ({
        isActive,
        account,
        isLoading,
        connect,
        disconnect,
        getCurrentWalletConnected,
        shouldDisable
      }),
      [isActive, isLoading, shouldDisable, account]
    )

    return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>
}

export default function useMetaMask() {
    const context = React.useContext(MetaMaskContext)

    if (context === undefined) {
        throw new Error('useMetaMask hook must be used with a MetaMaskProvider component')
    }

    return context
}