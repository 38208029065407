// eslint-disable-next-line
import React from 'react'
import Web3 from 'web3'
import useMetaMask from './useMetaMask'
import { kiwiiContractAddress } from '../contract'

require('dotenv').config()
const newKiwiiABI = require('../abi/KiwiiEggs')

const RPC_URL = window.__RUNTIME_CONFIG__.REACT_APP_RPC_URL

const httpProvider = new Web3.providers.HttpProvider(RPC_URL)
const web3 = new Web3(new Web3(window.ethereum || httpProvider))

const newKiwiiContract = new web3.eth.Contract(newKiwiiABI.abi, kiwiiContractAddress)

export const useAdmin = () => {

    const { account } = useMetaMask()

    const getOnlyWhitelisted = async () => {
        try {
          const result = await newKiwiiContract.methods.onlyWhitelisted().call()
          return result
        } catch (error) {
          console.error(error)
        }
    }
      
    const setOnlyWhiteListedToTrue = async () => {
      try {
        const result = await newKiwiiContract.methods.setOnlyWhitelisted1().send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const setOnlyWhiteListedToFalse = async () => {
      try {
        const result = await newKiwiiContract.methods.setOnlyWhitelisted2().send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const onlyWhitelist = async (value) => {
      try {
        const result = await newKiwiiContract.methods.setOnlyWhitelisted(value).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const setNewCost = async (newCost) => {
      try {
        const result = await newKiwiiContract.methods.setCost(newCost).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const whitelistAddress = async (addresses, id) => {
      try {
        const result = await newKiwiiContract.methods.addWhitelistUserAddress(addresses, id).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const withdrawFund = async (address) => {
      try {
        const result = await newKiwiiContract.methods.withdraw(address).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const getPaused = async () => {
      try {
        const result = await newKiwiiContract.methods.paused().call()
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const setPauseTrue = async () => {
      try {
        const result = await newKiwiiContract.methods.pause1().send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const setPauseFalse = async () => {
      try {
        const result = await newKiwiiContract.methods.pause2().send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const baseURI = async (value) => {
      try {
        const result = await newKiwiiContract.methods.setBaseURI(value).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const notBaseReveal = async (value) => {
      try {
        const result = await newKiwiiContract.methods.setNotRevealeURI(value).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }
      
    const adminFunctionRole = async () => {
      try {
        const result = await newKiwiiContract.methods.ADMIN_FUNCTION_ROLE().call()
        return result
      } catch (error) {
        console.error(error)
      }
    }

    const superAdminRole = async () => {
      try {
        const result = await newKiwiiContract.methods.SUPER_ADMIN_ROLE().call()
        return result
      } catch (error) {
        console.error(error)
      }
  }
      
    const grantRole = async (role, address) => {
        try {
          const result = await newKiwiiContract.methods.grantRole(role, address).send({from: account})
          return result
        } catch (error) {
          console.error(error)
        }
    }
      
    const revoke = async (role, address) => {
        try {
          const result = await newKiwiiContract.methods.revokeRole(role, address).send({from: account})
          return result
        } catch (error) {
          console.error(error)
        }
    }

    const renounce = async (role, address) => {
      try {
        const result = await newKiwiiContract.methods.renounceRole(role, address).send({from: account})
        return result
      } catch (error) {
        console.error(error)
      }
    }

    return {
        revoke,
        renounce,
        superAdminRole,
        grantRole,
        adminFunctionRole,
        notBaseReveal,
        baseURI,
        setPauseFalse,
        setPauseTrue,
        getPaused,
        withdrawFund,
        whitelistAddress,
        setNewCost,
        onlyWhitelist,
        setOnlyWhiteListedToFalse,
        setOnlyWhiteListedToTrue,
        getOnlyWhitelisted
    }
}