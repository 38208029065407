import React from 'react';
import { Grid, Box } from '@mui/material';
import classNames from 'classnames';
import { SectionProps } from '../../../utils/SectionProps';
import Image from '../../elements/Image';
import Point from '../../../assets/images/point.png'
import Kiwii from '../../../assets/images/kiwii-egg-0014.jpg'


const contents = [
    {image: Point, heading: "Hatch Surprises", paragraph: "Kiwii Eggs might hatch surprises to you (whitelisted addresses, other NFTs…)"},
    {image: Point, heading: "Exclusive Access", paragraph: "As a Kiwii Egg owner, you will get exclusive and premier access to new NFT mints and airdrops coming out of Kiwii Labs"},
    {image: Point, heading: "Boost Your Assets", paragraph: "You will be able to boost your other Nahmii protocol assets (details exclusive to Kiwii holders)"}
]
const propTypes = {
    ...SectionProps.types
  }
  
  const defaultProps = {
    ...SectionProps.defaults
  }
  
  const OwnerExtras = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
  
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );
  
    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );
  
    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
            <div className={innerClasses}>
                <Box className="" >
                    <Grid container spacing={5}>
                        <Grid item md={5} sm={5} xs={12}>
                            <Image src={Kiwii} />
                        </Grid>
                        <Grid item md={7} sm={7} xs={12}>
                            <h4>Owner Extras</h4><br/>
                            { contents.map((content, index) => (
                                <Grid key={index} container>
                                    
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Box sx={{mt: -4}}>
                                            <h6 sx={{mb: -3, position: "relative"}}>{content.heading}</h6>
                                            <p sx={{mt: -5}}>{content.paragraph}</p>
                                        </Box>
                                    </Grid>
                                </Grid>
                                
                            )) }
                            
                        </Grid>
                    </Grid>
                    
                </Box>
            </div>
        </div>
      </section>
    );
  }
  
  OwnerExtras.propTypes = propTypes;
  OwnerExtras.defaultProps = defaultProps;
  
  export default OwnerExtras;