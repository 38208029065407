import React from "react";
import { Grid, Box } from '@mui/material'
import LayoutDefault from "../layouts/LayoutDefault";
import Withdraw from "../components/sections/admin/Withdraw";
import SetOnlyWhitelisted from "../components/sections/admin/SetOnlyWhitelisted";
import AddWhitelistUserAddress from "../components/sections/admin/AddWhitelistUserAddress";
import SetCost from "../components/sections/admin/SetCost";
import Pause from "../components/sections/admin/Pause";
import SetBaseURI from "../components/sections/admin/SetBaseURI";
import GrantRole from "../components/sections/admin/GrantRole";
import RevokeRole from "../components/sections/admin/RevokeRole";
import RenounceRole from "../components/sections/admin/RenounceRole";

const Admin = () => {
    return (
        <LayoutDefault>
            <Box sx={{m: 3}} style={{marginTop: "100px"}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                        <Pause/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <SetOnlyWhitelisted/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <AddWhitelistUserAddress/>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{m: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                        <GrantRole/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <RevokeRole/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <RenounceRole/>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{m: 3}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} sm={4}>
                        <SetBaseURI/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <SetCost/>
                    </Grid>
                    <Grid item xs={12} md={4} sm={4}>
                        <Withdraw/>
                    </Grid>
                </Grid>
            </Box>
            
        </LayoutDefault>
    )
}

export default Admin